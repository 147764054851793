<template>
    <span>{{ formatDate }}</span>
</template>

<script>
import moment from "moment";

export default {
    props: {
        date: String,
        time: {
            type: Boolean,
            default: false
        },
        format: {
            type: String,
            default: 'DD-MM-YYYY'
        }
    },
    computed: {
        fullFormat() {
            return this.format + (this.time ? ' HH:mm' : '');
        },
        formatDate() {
            if (!this.date) return '';
            return moment(this.date).format(this.fullFormat);
        }
    }
}
</script>
<template>
    <div>
        <v-card v-show="loading">
            <v-skeleton-loader type="card-heading"></v-skeleton-loader>
            <v-skeleton-loader type="table-thead, table-tbody"></v-skeleton-loader>
        </v-card>
        <v-card v-show="!loading">
            <div class="float-right pa-3">
                <router-link :to="{name: 'ordinativo_checklist', params: {id: ordinativo.id}}">
                    <v-btn color="primary">
                        Dettaglio
                    </v-btn>
                </router-link>
                <v-btn class="ml-2" color="success" @click.prevent="salva">Salva</v-btn>
            </div>
            <v-card-title>
                <div>
                    Ordinativo: {{ ordinativo.ordine }} - {{ ordinativo.descrizione_lotto }}<br>
                    Checklist: {{ checklist.nome }}
                </div>
            </v-card-title>
            <v-container>
                <v-row>
                    <v-col cols="3">
                        <label for="">Data Ispezione</label><br>
                        <LocalDate :date="ordinativo.data_pianificazione" time/>
                        <v-btn @click="modificaDataPianificazione" icon>
                            <v-icon>mdi-calendar</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="3">
                        <Relation v-model="ordinativo.referente"
                                  url="/backend/autocomplete/userprofile" label="Referente"
                                  :filters="{ruoli:'pa'}">
                        </Relation>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <label>Referente Nome: </label> <br>
                        <v-text-field v-model="ordinativo.referente_nome"/>
                    </v-col>
                    <v-col cols="3">
                        <label>Referente Cognome: </label> <br>
                        <v-text-field v-model="ordinativo.referente_cognome"/>
                    </v-col>

                    <v-col cols="3">
                        <label>Referente E-mail: </label> <br>
                        <v-text-field v-model="ordinativo.referente_email" type="email"/>
                    </v-col>
                    <v-col cols="3">
                        <label>Referente Telefono: </label> <br>
                        <v-text-field v-model="ordinativo.referente_telefono"/>
                    </v-col>

                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-checkbox label='Penali' name="penali" v-model="ordinativo.penali"></v-checkbox>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Penali Note" v-model="ordinativo.penali_note"></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-checkbox label='Interrotta' name="interrotta" v-model="ordinativo.interrotta"></v-checkbox>
                    </v-col>
                    <v-col cols="3">
                        <v-checkbox label='Cambio Ordinativo' name="cambio_codice"
                                    v-model="ordinativo.cambio_codice"></v-checkbox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-text-field label="Amministrazione" v-model="ordinativo.amministrazione"></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Comune" v-model="ordinativo.amministrazione_comune"></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="Indirizzo" v-model="ordinativo.amministrazione_indirizzo"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-textarea label="Note" v-model="ordinativo.note"></v-textarea>
                    </v-col>
                </v-row>
            </v-container>
            <v-data-table
                :headers="headers"
                :items="requisiti"
                :loading="saving"
                group-by="s_requisito.s_categoria.nome"
                hide-default-footer
                disable-pagination
            >
                <template #group.header="{group, isOpen, headers, toggle}">
                    <td :colspan="headers.length">
                        <v-btn @click="toggle" x-small icon :ref="group">
                            <v-icon v-if="isOpen">mdi-minus</v-icon>
                            <v-icon v-else>mdi-plus</v-icon>
                        </v-btn>
                        <span class="mx-5 font-weight-bold">{{ group }}</span>
                    </td>
                </template>
                <template #item.livelli="{item}">
                    <v-select v-if="risultati[item.s_requisito.id]" v-model="risultati[item.s_requisito.id].livello"
                              :items="livelli(item)"
                              item-text="descrizione" item-value="id"></v-select>
                </template>
                <template #item.note="{item}">
                    <v-textarea auto-grow outlined rows="6" v-if="risultati[item.s_requisito.id]" class="mt-3"
                                v-model="risultati[item.s_requisito.id].note"></v-textarea>
                </template>
                <template #item.files="{item}">
                    <div class="pa-3" v-if="risultati[item.s_requisito.id] && risultati[item.s_requisito.id].id">
                        <v-dialog width="500px">
                            <template #activator="{on, attrs}">
                                <v-btn icon color="success" v-bind="attrs" v-on="on">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <template #default="dialog">
                                <v-card class="pa-3">
                                    <v-card-title>Aggiungi allegato</v-card-title>
                                    <v-card-text>
                                        <v-file-input v-model="allegato" label="Allegato"></v-file-input>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn @click="dialog.value = false">Annulla</v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn @click="uploadAllegato(risultati[item.s_requisito.id].id, dialog)"
                                               color="primary">
                                            Conferma
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                        <v-data-table
                            :headers="[{value:'nomefile'},{value:'data_ora'}, {value:'actions'}]"
                            :items="allegati[risultati[item.s_requisito.id].id]"
                            hide-default-header
                            hide-default-footer
                        >
                            <template #item.actions="{item}">
                                <v-btn icon color="primary" @click="download(item.id)">
                                    <v-icon>mdi-download</v-icon>
                                </v-btn>
                                <Confirm message="Confermi la cancellazione del file?"
                                         @confirm="deleteAllegato(item.id)">
                                    <template #activator="{on, attrs}">
                                        <v-btn icon color="error" v-bind="attrs" v-on="on">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                </Confirm>
                            </template>
                        </v-data-table>
                    </div>
                    <div v-else>
                        Per aggiungere allegati salvare prima
                    </div>
                </template>
            </v-data-table>
        </v-card>
        <v-dialog v-model="dialogPianifica" width="700">
            <v-card class="pa-3">
                <v-card-title class="mb-3">
                    Modifica Data Pianificazione:
                </v-card-title>
                <v-card-text>
                    <v-row justify="space-around">
                        <v-date-picker v-model="pianificaData"></v-date-picker>
                        <v-time-picker format="24hr" v-model="pianificaOra"></v-time-picker>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn text @click="dialogPianifica=false">Annulla</v-btn>
                    <v-spacer/>
                    <v-btn color="primary" @click="imposta_data">Conferma</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Relation from "@/components/Relation";
import Confirm from "@/components/Confirm";
import LocalDate from "@/components/LocalDate";

export default {
    data: () => ({
        loading: true,
        saving: false,
        ordinativo: {},
        checklist: {},
        requisiti: [],
        compilazione: {},
        risultati: {},
        allegati: {},
        allegato: null,
        headers: [
            {width: '25%', text: 'Descrizione', value: 's_requisito.descrizione', sortable: false},
            {width: '25%', text: 'Livello', value: 'livelli', sortable: false},
            {width: '25%', text: 'Note', value: 'note', sortable: false},
            {width: '25%', text: 'Allegati', value: 'files', sortable: false},
        ],
        dialogPianifica: false,
        pianificaData: null,
        pianificaOra: null,
    }),
    mounted() {
        this.setup();
    },
    methods: {
        async setup() {
            const ordinativoId = this.$route.params.id;
            await this.loadOrdinativo(ordinativoId);
            await this.loadCompilazione();
            await this.loadAllegati();
        },
        async loadOrdinativo(id) {
            const res = await this.$http.get(`/audit/ordinativo/${id}/`);
            if (res.ok) {
                this.ordinativo = res.result.data;
                const checklistId = this.ordinativo.s_convenzione.checklist;
                await this.loadChecklist(checklistId);
                await this.loadRequisiti(checklistId);
            }
        },
        async loadChecklist(id) {
            const res = await this.$http.get(`/checklist/checklist/${id}/`);
            if (res.ok) {
                this.checklist = res.result.data;
            }
        },
        async loadRequisiti(id) {
            const res = await this.$http.get(`/checklist/${id}/requisito/`);
            if (res.ok) {
                this.requisiti = res.result.data;
            }
        },
        async loadCompilazione() {
            const res = await this.$http.get(`/checklist/compilazione/`, {ordinativo: this.ordinativo.id});
            if (res.ok) {
                const items = res.result.data.results;
                if (items.length > 0) {
                    this.compilazione = items[0];
                }
                await this.loadRisultati();
            }
        },
        async loadRisultati() {
            const id = this.compilazione.id;
            if (id) {
                const res = await this.$http.get(`/checklist/risultato/`, {compilazione: id});
                if (res.ok) {
                    for (const item of res.result.data) {
                        this.$set(this.risultati, item.requisito, item);
                    }
                }
            }
            this.requisiti.forEach(item => {
                const req_id = item.s_requisito.id;
                if (this.risultati[req_id]) return;
                this.$set(this.risultati, req_id, {
                    requisito: req_id,
                    livello: null,
                    note: item.s_requisito.note_default,
                });
            });
            this.loading = false;
        },
        async salva() {
            this.saving = true;
            if (!this.compilazione.id) {
                const res = await this.$http.post('/checklist/compilazione/', {
                    checklist: this.checklist.id,
                    ordinativo: this.ordinativo.id,
                });
                if (res.ok) {
                    this.compilazione = res.result.data;
                }
            }
            const params = {
                compilazione: this.compilazione.id,
                risultati: Object.values(this.risultati),
            }
            const res = await this.$http.post(`/checklist/risultato/compila/`, params);
            if (res.ok) {
                for (const item of res.result.data) {
                    this.$set(this.risultati, item.requisito, item);
                }
            }
            this.saving = false;
            if (this.compilazione.id)
                await this.$http.patch('/audit/ordinativo/' + this.ordinativo.id + '/', this.ordinativo);
            else
                await this.$http.post('/audit/ordinativo/' + this.ordinativo.id + '/', this.ordinativo);

            this.notify('Salvataggio completato!');

        },
        async loadAllegati() {
            const res = await this.$http.get('/checklist/allegato/', {compilazione: this.compilazione.id});
            if (res.ok) {
                this.allegati = {};
                for (const item of res.result.data) {
                    const key = item.risultato;
                    if (!this.allegati[key])
                        this.allegati[key] = [];
                    this.allegati[key].push(item);
                }
            }
        },
        async uploadAllegato(id, dialog) {
            const res = await this.$http.postFile(`/checklist/risultato/${id}/allegato/upload`, this.allegato);
            if (res.ok) {
                await this.loadAllegati();
            }
            this.allegato = null;
            dialog.value = false;
        },
        async deleteAllegato(id) {
            const res = await this.$http.delete(`/checklist/allegato/${id}/`);
            if (res.ok) {
                await this.loadAllegati();
            }
        },
        download(id) {
            this.$http.downloadFile(`/checklist/allegato/${id}/download/`);
        },
        livelli(item) {
            return [{id: null, descrizione: '-'}, ...item.s_requisito.s_livelli];
        },
        modificaDataPianificazione() {
            const [data, ora] = this.ordinativo.data_pianificazione.split('T');
            this.pianificaData = data;
            this.pianificaOra = ora.substring(0, 8);
            this.dialogPianifica = true;
        },
        imposta_data() {
            this.dialogPianifica = false;
            this.ordinativo.data_pianificazione = this.pianificaData + 'T' + this.pianificaOra;
        },
    },
    components: {
        Confirm,
        Relation,
        LocalDate
    }
}
</script>
